import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { observable } from "mobx";
import { InboxImage } from "../../components/icons";
import PrimaryTitle from "../../components/PrimaryTitle";
import NoContent from "../../components/NoContent";
import t from "../../service/translate.service";
import PropTypes from "prop-types";
import iconsArrowRight from "../../assets/svg/iconsArrowRight.svg";
import IconsCloseSmall from "../../assets/svg/iconsCloseSmall.svg";
import moment from "moment";
import RepairsAndIquiriesStore from "../../store/repairsAndInquiries.store";
import { getAlertProperties } from "../../service/alert.service";
import history from "../../service/history.service";
import withRouter from "../../components/withRouter";
import * as DOMPurify from "dompurify";
import { inboxCategories } from "../../config";

class InboxPage extends Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props);
    this.store = new RepairsAndIquiriesStore();
    this.store.getReports();
  }

  handleClick = (type) => {
    this.props.alertStore.selectedCategory = type;
  };

  openReport = (id) => {
    if (this.props.alertStore.selectedCategory === "damage_report") {
      const selectedReport = this.store.reports.filter((r) => r.id === id)[0];
      this.store.selectedReport = selectedReport;
      this.store.showResolved = selectedReport.statusId === 3;
      this.props.menuStore.setCurrentPage("/repairs-and-inquiries");
      this.props.navigate("/repairs-and-inquiries");
    }
    if (this.props.alertStore.selectedCategory === "oc_inspection") {
      this.props.menuStore.setCurrentPage("/receipt-inspection");
      this.props.navigate("/receipt-inspection");
    }
    if(this.props.alertStore.selectedCategory === "contract") {
      this.props.menuStore.setCurrentPage("/contracts");
      this.props.navigate("/contracts");
    }
  };

  renderContent = (content) => {
    const regex =
      /(https?:\/\/[^\s<>"',]+(?<![.,]))|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const parts = content.split(regex);

    return parts.map((part, index) => {
      if (part.match(regex)) {
        return (
          <a
            key={index}
            href={part.startsWith("http") ? part : `mailto:${part}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  renderInboxAlert = (alert) => {
    if (alert.type !== this.props.alertStore.selectedCategory) return;
    if(!this.props.menuStore.emailVerificationEnabled && alert.status === 'UNVERIFIED') {
      alert.status = 'VERIFIED'
    }
    const { statusName, statusClass } = getAlertProperties(alert);
    let content =
      localStorage.getItem("locale") === "en" ? alert.content : alert.contentDe;
    content = DOMPurify.sanitize(content);
    const regEx =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    const isUrl = regEx.test(content);
    content = isUrl ? this.renderContent(content) : content;
    let sName = statusName;
    const foundCategory = inboxCategories.find(
      (category) => category.type === statusName
    );
    if (foundCategory) {
      sName = foundCategory.name;
    }
    return (
      <div className="item" key={alert.id}>
        <div className="container">
          <div
            className="details"
            onClick={() => {
              this.props.alertStore.markAlertRead(alert.id);
              this.openReport(alert.reportId);
            }}
          >
            <div className="group">
              <div className="status">
                <span className={`status ${statusClass}`}>
                  {t[statusClass]}
                </span>{" "}
                {sName} {!alert.seen && <span className="new">{t.NEW}</span>}
              </div>
              {!isUrl ? (
                <div
                  className="contract-number"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                ></div>
              ) : (
                <div>
                  <p>{content}</p>
                </div>
              )}

              <div className="date">
                {moment(alert.created_at)
                  .lang(
                    `${localStorage.getItem("locale") === "de" ? "de" : "en"}`
                  )
                  .format("dddd, MMMM Do YYYY, HH:mm")}
              </div>
            </div>
            {this.props.alertStore.selectedCategory === "damage_report" && (
              <div className="icon next">
                <img src={iconsArrowRight} />
              </div>
            )}
          </div>
          <div
            className="close"
            onClick={() => {
              this.props.alertStore.deleteAlert(alert.id);
            }}
          >
            <img src={IconsCloseSmall} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const categoryStyle = {
      paddingRight: 30,
    };
    const { alerts, categories, selectedCategory } = this.props.alertStore;
    return (
      <div>
        <PrimaryTitle title={t.INBOX_TITLE} />

        {!alerts.length && (
          <NoContent message={t.INBOX_EMPTY} image={InboxImage} />
        )}

        {!!alerts.length && (
          <div className="category-tabs">
            {categories.map((c) => {
              if (!c.count) return;
              return (
                <span
                  className={selectedCategory === c.type ? "active" : ""}
                  key={c.type}
                  style={categoryStyle}
                  onClick={() => this.props.alertStore.handleClick(c.type)}
                >
                  <h3>{c.name}</h3>
                  {!!c.newCount && <div className="new">{c.newCount}</div>}
                </span>
              );
            })}
          </div>
        )}

        <div className="alert-list">
          {alerts.map((alert) => this.renderInboxAlert(alert))}
        </div>
      </div>
    );
  }
}
export function observableWithRouter(component) {
  return withRouter(observable(component));
}

InboxPage.propTypes = {
  menuStore: PropTypes.object.isRequired,
  alertStore: PropTypes.object.isRequired,
};
export default inject(
  "alertStore",
  "menuStore"
)(withRouter(observer(InboxPage)));
