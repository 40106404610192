import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PrimaryTitle from '../../components/PrimaryTitle';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import withRouter from '../../components/withRouter';
import t from '../../service/translate.service';
import { Alert, Button } from 'antd';
import { extendObservable } from 'mobx';

class ContractVerificationPage extends Component {
    static propTypes = {
        contractStore: PropTypes.object.isRequired,
        isAddingContract: PropTypes.bool.isRequired,
        redirectUrl: PropTypes.string,
        loyaltyCashOut: PropTypes.bool,
        onCancel: PropTypes.func,
        loyaltyContractNumber: PropTypes.string
    };

    constructor(props) {
        super(props);
        extendObservable(this, {
            submitSuccess: false,
            err: null,
            selectedEmail: '',
            resendEmailAttempts: 0
        });
    }

    async componentDidMount() {
        const contractNumber = this.props.contractStore.contract.contractNumber || this.props.contractStore.selectedContract.contractNumber;
        await this.props.contractStore.getContractDetails(this.props.loyaltyContractNumber || contractNumber);
    }

    verifyUserEmail(email) {
        const canSendEmail = this.checkIfCanResendEmail()
        if(!canSendEmail) return
        this.selectedEmail = email;
        this.props.contractStore
            ?.verifyEmail(email, this.props.isAddingContract, this.props.redirectUrl, this.props.loyaltyCashOut, this.props.loyaltyContractNumber)
            .catch(() => (this.err = true));
    }

    formatEmail(email) {
        if (email.length) {
            const [name, domain] = email.split("@");
            return `${name[0]}${"*".repeat(name.length - 3)}@${domain}`;
        }
        return email;
    }

    checkIfCanResendEmail() {
        this.resendEmailAttempts++
        if(this.resendEmailAttempts >= 3) return false
        else return true
    }

    render() {
        const { error, verificationEmailSent, contractEmails } = this.props.contractStore;

        return (
            <div className='thank-you-overlay'>
                <div className='wrapper' style={{ maxWidth: 600 }}>
                    {error && <Alert type='error' message={error} />}
                    <PrimaryTitle title={t.CONTRACT_VERIF_PAGE_TITLE_1} />
                    <div className='description'>
                        {verificationEmailSent
                            ? `${t.CONTRACT_VERIF_PAGE_TITLE_3}${this.formatEmail(this.selectedEmail)}. ${t.CONTRACT_VERIF_PAGE_TITLE_4}`
                            : t.CONTRACT_VERIF_PAGE_TITLE_2}
                    </div>
                     {this.resendEmailAttempts >= 3 && <div style={{marginBottom: 15}}>{t.CONTRACT_VERIF_PAGE_TITLE_5}</div>}
                    {contractEmails.length > 0 &&
                        contractEmails.map((email, index) => (
                            <Button
                                disabled={this.resendEmailAttempts >= 3}
                                key={index}
                                style={{ marginBottom: 10, height: 50 }}
                                className='button'
                                onClick={() => this.verifyUserEmail(email)}
                            >
                                {verificationEmailSent ? `${t.CONTRACT_VERIF_RESEND} ${this.formatEmail(email)}` : `Email (${this.formatEmail(email)})`}
                            </Button>
                        ))}
                    {!this.props.onCancel ? (
                        <Link to='/' className='button tertiary'>{t.FORM_CANCEL}</Link>
                    ) : (
                        <Button onClick={this.props.onCancel}>{t.FORM_CANCEL}</Button>
                    )}
                </div>
            </div>
        );
    }
}

export default inject('contractStore')(withRouter(observer(ContractVerificationPage)));
