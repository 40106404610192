import React, { Component } from 'react'
import ContractCard from '../../components/ContractCard'
import map from 'lodash/map'
import propTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { extendObservable } from 'mobx'
import withRouter from '../../components/withRouter'
import { checkIsUserCondominium } from '../../service/user.service'
import contractService from '../../service/contract.service'
import ContractVerificationPage from './ContractVerificationPage'

class ContractList extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    extendObservable(this, {
      modal: {
        visible: false,
      }
    })
  
  } 

  componentDidMount() {
    this.props.contractStore.verificationStarted = false
  }

  showModal = () => {
    this.modal.visible = true
  }

  openContractOverview = (contract) => {
    if(!contract.active || !contract.valid) return
    if(contractService.checkIsContractCondominium(contract.contractNumber)) return
    this.props.contractStore.selectedContract = contract
    this.props.navigate(`/contract-overview/${contract.contractNumber}`)
  }

  checkIfCanOpenContract = (contract) => {
    if(!contract[0].active || !contract[0].valid) return true
    return checkIsUserCondominium(contract) 
  }

  closeVerificationModal = () => {
    this.showVerificationSuccessModal = false
  } 

  render() {
    return (
      <div className='contract-list'>
      {this.props?.contractStore?.verificationStarted &&  (
        <ContractVerificationPage isAddingContract={false} redirectUrl='/contracts'/>
      )
      }
        {Boolean(this.props.contractStore.contracts.length) && this.props.contractStore.isRabFetched && (
          <div className='contracts-holder'>
            {map(this.props.contractStore.contracts, c => (
              <div className={`contractCardWrapper ${c.rentAccountBalance === '' || !this.props.contractStore.rentAccountEnabled && 'pointerClass'} `}  key={c.id}>
                <ContractCard
                  clickable={this.checkIfCanOpenContract([c]) ? false : true}
                  key={c.id}
                  contract={c}
                  rentAccEnabled={this.props.contractStore.rentAccountEnabled}
                  currContractNum={this.currContractNum}
                  onClick={this.openContractOverview}
                  contractStore={this.props.contractStore}
                  menuStore={this.props.menuStore}
                   />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

export default inject('contractStore', 'menuStore')(withRouter(observer(ContractList)))
